import { useEffect, useReducer, useMemo } from 'react';
import update from 'immutability-helper';
import { navigate } from 'gatsby';
import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { Link } from 'components';
import { AppPage } from 'app/layout';
import { useAppDispatch, createProfile, useAppSelector } from 'app/store';
import { QuestionStepper } from 'app/survey/questions';
import { getProfileQuestions, UserCategory } from 'app/profile/getProfileQuestions';
import create_profile from 'app/profile/create_profile.png';

import type { state } from 'app';

import { ensureAuth } from 'app/helpers';

type CreateProfileState = {
  step: number;
  user: Partial<state.User>;
};

type Action =
  | {
      type: 'set-step';
      payload: number;
    }
  | {
      type: 'update-user';
      payload: Partial<state.User>;
    };

const setStep = (step: number): Action => {
  return {
    type: 'set-step',
    payload: step,
  };
};

const updateUser = (data: Partial<state.User>): Action => {
  return {
    type: 'update-user',
    payload: data,
  };
};

const createProfileReducer = (state: CreateProfileState, action: Action) => {
  switch (action.type) {
    case 'set-step':
      return update(state, { step: { $set: action.payload } });

    case 'update-user':
      return update(state, { user: { $merge: action.payload } });

    default:
      return state;
  }
};

function CreateProfilePage({ onSubmit }: { onSubmit: (data: state.User) => void }) {
  const [state, dispatch] = useReducer(createProfileReducer, {
    step: -1,
    user: { userContactAgreement: false, userConsent: false, emailNotification: true, smsNotification: false },
  });
  const { step, user } = state;
  const questions = useMemo(() => {
    const userCategory = () => {
      const { userCategory } = user;
      if (userCategory) {
        return userCategory === 'participant' ? UserCategory.Participant : UserCategory.Other;
      }
    };
    return getProfileQuestions({ userCategory: userCategory() });
  }, [user]);

  useEffect(() => {
    if (step === questions.length) {
      onSubmit(state.user as state.User);
    }
  }, [step]);

  return (
    <AppPage title="Create Profile">
      <Box pt={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/">
            Home
          </Link>
          <Link color="inherit" href="/dashboard">
            Dashboard
          </Link>
          <Typography color="textPrimary">Create profile</Typography>
        </Breadcrumbs>

        <Grid
          container
          alignItems="center"
          sx={{
            bgcolor: blueGrey[50],
            mt: 2,
          }}>
          <Grid item sm={12} md={6}>
            <Box
              p={4}
              display="flex"
              justifyContent="center"
              sx={{
                '&>img': {
                  width: '80%',
                  height: '80%',
                  maxWidth: '300px',
                  maxHeight: '300px',
                },
              }}>
              <img src={create_profile} />
            </Box>
          </Grid>

          <Grid item sm={12} md={6}>
            <Box p={4}>
              <Typography variant="h4" gutterBottom>
                Welcome
              </Typography>

              <Typography variant="body1" color="textSecondary" paragraph>
                Welcome and congratulations on taking this step to protect your child’s mental wellbeing. First, please
                take some time to read the information below and complete your profile.
              </Typography>

              <Box mb={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={step >= 0}
                  onClick={() => dispatch(setStep(0))}
                  sx={{
                    mt: 1,
                    mr: 1,
                  }}>
                  Start
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <QuestionStepper
              sections={questions}
              step={step}
              setStep={(step) => dispatch(setStep(step))}
              data={user}
              onChange={(data) => dispatch(updateUser(data))}
              finishLabel="I Agree"
            />
          </Grid>
        </Grid>
      </Box>
    </AppPage>
  );
}

export function CreateProfile() {
  const dispatch = useAppDispatch();
  const profileData = useAppSelector((state) => state.profile.data);

  if (profileData !== null) {
    return (
      <Dialog open={true}>
        <DialogTitle>Thank you</DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            You have now completed your profile. Check out your personal dashboard for the next steps of your program.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => navigate('/dashboard', { replace: true })}>Go</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return <CreateProfilePage onSubmit={(data) => dispatch(createProfile(data))} />;
}

export default ensureAuth(CreateProfile, false);
